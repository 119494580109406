import React from "react"

import { Container } from 'reactstrap' 
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from '../components/slider-equipment' 
import worldIcon from '../assets/images/world.png' 
import crossImg from '../assets/images/point-cross.png' 
import redValue from '../assets/images/red-star.png' 
import oea from '../assets/images/oea.png' 
import CTPAT from '../assets/images/CTPAT.png' 
 
export default () => (
  <Layout>
    <SEO title="Equipment" />
    <Slider title="EQUIPMENT" />
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<h4 className="surpassing"><b>SURPASSING</b> ALL BOUNDARIES FROM <b>US</b> TO <b>YOU</b></h4> 
			  </div>
			</div>
		</Container>
	</div>
	<div className="equipment-background" >
		<div className="truck-equipment-background" >
		</div>
		<Container className="pt-4 container_height">
			<div className="row rowEquipment"> 
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{'121'}</h4> 
						<p className="blueBlockP">{'TRUCKS'}</p>  
						<hr/>  
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{'350'}</h4> 
						<p className="blueBlockP">{'54" DRY VANS'}</p>    
						<hr/> 
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{'43'}</h4> 
						<p className="blueBlockP">{'48" FLAT BEDS'}</p>  
						<hr/>   
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<div className="blueBlockDiv">  
							<h4 className="blueBlockH">{''}</h4>  
							<p className="blueBlockP">{'PANELS'}</p>    
							<hr/> 
						</div> 
					</div> 
			    </div> 
			</div>
		</Container>
	</div>  
	<div className="equipment-background md-none" >
		<div className="truck-equipment-background-2" >
		</div>
		<Container className="pt-4 container_height">
			<div className="row rowEquipment"> 
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{''}</h4> 
						<p className="blueBlockP2">{'24" BOB TAILS TRUCK'}</p>  
						<hr/>  
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{''}</h4> 
						<p className="blueBlockP2">{'STEP DECK'}</p>    
						<hr/> 
					</div> 
			    </div>
			    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
					<div className="blueBlock">  
						<h4 className="blueBlockH">{''}</h4> 
						<p className="blueBlockP2">{'REEFERS'}</p>  
						<hr/>   
					</div> 
			    </div> 
			</div>
		</Container>
	</div>  
	<div className="cross-equipment-background" > 
		<img className="crossImg" src={crossImg} alt="..."/>
		<Container className="pt-4"> 
			<div className="row">
			    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<img className="redValue" src={redValue} alt="red icon mission"/> 
					<h4 className="equipmentH">BENEFITS OF <br/><b>OUR EQUIPMENT</b></h4> 
					<p className="equipmentP">{'Excellence'}</p>  
			    </div> 
				<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 equipmentGray">
					<img className="CTPAT" width={240} src={CTPAT} alt="..."/>
					<img className="oea" src={oea} alt="..."/>
					<h4 className="grayH_2">
					</h4>
					<p className="grayText">{'Equipment in excellent conditions of cleanness and exhaustive maintainance according to CTPAT and OEA regulations.'}</p>
				</div>			  
			</div>
		</Container>
	</div>   
  </Layout>
)
